import { useCustomerQueries } from '@customer/composables/useCustomerQueries/useCustomerQueries'
import type { CustomerAddressesListQueryVariables, CustomerRecentOrdersQueryVariables } from '#gql'

export function useCustomerData() {
  const pending = ref<boolean>(false)
  const errorState = ref<string | null>(null)
  const getCustomerData = async () => {
    pending.value = true
    errorState.value = null

    const { customerData } = useCustomerQueries()

    try {
      return await customerData()
    }
    catch (error) {
      errorState.value = error
    }
    finally {
      pending.value = false
    }
  }

  const getCustomerRecentOrders = async (variables?: CustomerRecentOrdersQueryVariables) => {
    const { customerRecentOrders } = useCustomerQueries()
    return await customerRecentOrders(variables)
  }

  const getCustomerAddresses = async (variables?: CustomerAddressesListQueryVariables) => {
    const { getCustomerAddressesList } = useCustomerQueries()
    return await getCustomerAddressesList(variables)
  }

  return {
    getCustomerData,
    getCustomerRecentOrders,
    getCustomerAddresses,
  }
}
